<script>
import Vue from 'vue';
import env from '../env';
import Gen from '../helper/Gen';
import Croppa from 'vue-croppa';
import moment from 'moment';

/* Vue plugins */
Vue.use(Croppa);
// Vue.component('croppa', () => import(Croppa.component));

import "@plugins/jquery/dist/jquery.min.js"

global.$ = global.jQuery
global.Gen = Gen
moment.locale("id")
global.moment = moment

import "@backend/css/custom.scss"

import "@plugins/extension.js"
import "@plugins/jquery-validation/jquery-validation.js"
import "@backend/js/jquery.slimscroll.js"
import "@backend/js/waves.js"
import "@backend/js/custom.js"
import "@backend/js/main.js"

// VueFormWizard
import VueFormWizard from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
Vue.use(VueFormWizard)

Vue.component("ImagePreview",()=>import("@backend/components/ImagePreview.vue"))
Vue.component("BoField",()=>import("@backend/components/BoField.vue"))
Vue.component("SortField",()=>import("@backend/components/SortField.vue"))
Vue.component("StatusLabel",()=>import("@backend/components/StatusLabel.vue"))
Vue.component("FileManager",()=>import("@components/FileManager.vue"))

export default {
  name:"BoMain",
  async mounted(){
    $("title").text("Backoffice - Farmsco Official Website");
    /* CSS */
    Gen.loadCss("/plugins/bootstrap/css/bootstrap.min.css")
    Gen.loadCss("/backend/css/animate.css")
    Gen.loadCss("/backend/css/style.css")
    Gen.loadCss("/backend/css/colors/blue.css")

    /* JS */
    await Gen.loadScript("/plugins/bootstrap/js/bootstrap.min.js")

    /* Ready Loaded */
    $(document).ready(()=>{
      this.$root.ready = true
    })

    $(document).on('keydown', '.form-control', function(e) {
	    if (e.which === 32 &&  e.target.selectionStart === 0) {
	      return false;
	    }  
	  });
  }
};
</script>

<template>
  <router-view/>
</template>